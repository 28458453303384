import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Link } from 'gatsby';

import { MoreProductsTitles, MoreProductsList } from './styles';


const MoreProducts = ({moreProducts}) => {
  return (
    <React.Fragment>
      { moreProducts.length > 0 &&
        <React.Fragment>
          <MoreProductsTitles>Podobne produkty:</MoreProductsTitles>
          <MoreProductsList>
            {
              moreProducts.map((product, index) => {
                return (
                  <li key={`${product}-${index}`}>
                    <ReactMarkdown
                      source={product}
                      renderers={{
                        link: (props) => {
                          if (!props.href.match('http')) {
                            return <Link to={props.href}>{props.children}</Link>;
                          }
                          return <a href={props.href} target="_blank"
                                    rel="nofollow noreferrer noopener">{props.children}</a>;
                        },
                        paragraph: (props) => {
                          return <span>{props.children}</span>
                        },
                      }}
                    />
                  </li>
                )
              })
            }
          </MoreProductsList>
        </React.Fragment>
      }
    </React.Fragment>
  );
};

export default MoreProducts;