import styled from 'styled-components';
import {minBreakpoint, palePurple, smallBreapoint} from '../../components/styles-settings';

export const DescriptionSection = styled.section`
  display: flex;
  flex-direction: column;
  
  h2 {
    font-size: 2rem;
    color: ${palePurple};
    text-align: left;
  }
  
  h3 {
    font-size: 1.5rem;
    color: ${palePurple};
  }
`;

export const Description = styled.div`
  text-align: left;
  margin-bottom: 3rem;
`;

export const GalleryWrapper = styled.section`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 3rem;
`;

export const GalleryItem = styled.div`
  width: 100%;
  padding: 0.5rem;
  max-height: 300px;
  
  @media (min-width: ${`${minBreakpoint}px`}) {
    width: 50%;
  }
  
  @media (min-width: ${`${smallBreapoint}`}) {
    width: 25%;
  }
`;

export const GalleryImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

export const MoreProductsWrapper = styled.section`
  text-align: left;
`;

export const MoreProductsTitles = styled.div`
  font-size: 1.5rem;
  margin-bottom: 0.75rem;
`;

export const MoreProductsList = styled.ul`
  list-style-type: disc;
  padding-left: 1.5rem;
`;