import React from 'react';
import PropTypes from 'prop-types';

import Layout from '../components/layout';
import Product from '../container/Product';


function OfferTemplate(props) {
  const {
    pageContext: { data },
  } = props;

  return (
    <Layout>
      <Product data={data} />
    </Layout>
  );
}

OfferTemplate.propTypes = {
  pageContext: PropTypes.shape({
    data: PropTypes.object,
  }).isRequired,
};

export default OfferTemplate;
