import React from 'react';
import Helmet from 'react-helmet';
import ReactMarkdown from 'react-markdown';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

import Jumbotron from '../../components/Jumbotron';
import MoreProducts from './MoreProducts';
import { SectionStyled } from '../../components/commons-styles';
import { FULL_PAGE_URL } from '../../../settings';
import {
  DescriptionSection,
  Description,
  GalleryWrapper,
  GalleryItem,
  GalleryImage,
  MoreProductsWrapper,
} from './styles';


function Product(props) {
  const { data } = props;

  if (!data) {
    return null;
  }

  const {
    meta,
    header,
    subHeader,
    imageItem,
    articleses,
    gallery,
    moreProducts,
  } = data;

  return (
    <React.Fragment>
      <Helmet
        title={meta.title}
        link={[{ rel: 'canonical', href: `${FULL_PAGE_URL}${meta.canonical}` }]}
        meta={[
          { name: 'description', content: `${meta.description}` },
          { name: 'keywords', content: `${meta.keywords}` },
          {
            name: 'robots',
            content: meta.robots ? `${meta.robots}` : 'index, follow',
          },
        ]}
      />
      <Jumbotron
        srcImage={imageItem && imageItem.image.url}
        minHeight="auto"
        minHeightMobile="auto"
        heightJumb="400px"
        header={header}
        subHeader={subHeader}
      />
      <SectionStyled breakpoint="verysmall">
        <DescriptionSection>
          {articleses && (
            articleses.map((article) => (
              <React.Fragment key={article.id}>
                <h2>{article.header}</h2>
                <Description>
                  <ReactMarkdown
                    source={article.text}
                    renderers={{
                      // eslint-disable-next-line react/prop-types
                      link: ({ href, children }) => {
                        // eslint-disable-next-line react/prop-types
                        if (!href.match('http')) {
                          return <Link to={href}>{children}</Link>;
                        }
                        return (
                          <a
                            href={href}
                            target="_blank"
                            rel="nofollow noreferrer noopener"
                          >
                            {children}
                          </a>
                        );
                      },
                      // eslint-disable-next-line react/prop-types
                      root: ({ children }) => (
                        <React.Fragment>{children}</React.Fragment>
                      ),
                    }}
                  />
                </Description>
              </React.Fragment>
            )))}
        </DescriptionSection>
        {gallery && (
          <GalleryWrapper>
            {gallery.imageItems.map((image) => (
              <GalleryItem key={image.id}>
                <GalleryImage
                  src={image.image.url}
                  alt={image.alt}
                  title={image.title}
                />
              </GalleryItem>
            ))}
          </GalleryWrapper>
        )}
        <MoreProductsWrapper>
          <MoreProducts moreProducts={moreProducts} />
        </MoreProductsWrapper>
      </SectionStyled>
    </React.Fragment>
  );
}

Product.propTypes = {
  data: PropTypes.shape({
    meta: PropTypes.shape({
      keywords: PropTypes.string,
      description: PropTypes.string,
      title: PropTypes.string,
      canonical: PropTypes.string,
      robots: PropTypes.string,
    }),
    header: PropTypes.string,
    subHeader: PropTypes.string,
    imageItem: PropTypes.object,
    articleses: PropTypes.array,
    gallery: PropTypes.array,
    moreProducts: PropTypes.object,
  }).isRequired,
};

export default Product;
