import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types';

import Container from '../Container';
import {
  baseWhite,
  basePurple,
  smallBreapoint,
  minBreakpoint,
} from '../styles-settings';
import { fancyBackground } from '../commons-styles';

const JumbotronStyles = styled.header`
  ${fancyBackground};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 0;
  padding: 3rem 1rem;
  border-bottom: 2px solid ${basePurple};

  @media (min-width: ${`${minBreakpoint}px`}) {
    min-height: ${props => props.minHeightMobile};
    height: ${props => props.heightJumb};
    background: url(${props => props.srcImage}) no-repeat center center fixed;
    background-size: cover;
    border-bottom: 0;
  }
  @media (min-width: ${`${smallBreapoint}px`}) {
    min-height: ${props => props.minHeight};
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    background: ${props => props.overlayColor};
  }
`;

const JumbotronTextStyles = styled(Container)`
  z-index: 10;
  text-align: center;
  width: 100%;
`;

const JumbotronHeader = styled.h1`
  color: ${baseWhite};
`;

const JumbotronHeaderDescription = styled.h2`
  color: ${baseWhite};
  font-size: 1.5rem;
  font-weight: 500;
  padding: 0 1rem;
  width: 80%;
  margin: auto;
`;

function Jumbotron({
  srcImage,
  minHeight,
  minHeightMobile,
  heightJumb,
  overlayColor,
  header,
  subHeader,
}) {
  return (
    <JumbotronStyles
      srcImage={srcImage}
      minHeight={minHeight}
      minHeightMobile={minHeightMobile}
      heightJumb={heightJumb}
      overlayColor={overlayColor}
    >
      <JumbotronTextStyles breakpoint="verysmall">
        <JumbotronHeader>{header}</JumbotronHeader>
        {subHeader && (
          <JumbotronHeaderDescription>{subHeader}</JumbotronHeaderDescription>
        )}
      </JumbotronTextStyles>
    </JumbotronStyles>
  )
}

Jumbotron.defaultProps = {
  overlayColor: 'rgba(0,0,0, 0.6)',
  heightJumb: 'auto',
  minHeight: 'inherit',
  minHeightMobile: 'inherit',
};

Jumbotron.propTypes = {
  header: PropTypes.string.isRequired,
  subHeader: PropTypes.string.isRequired,
  // eslint-disable-next-line
  srcImage: PropTypes.object.isRequired,
  minHeight: PropTypes.string,
  minHeightMobile: PropTypes.string,
  heightJumb: PropTypes.string,
  overlayColor: PropTypes.string,
};

export default Jumbotron;
